import React,{useState,useEffect} from 'react';
import '../css/Brand.css';
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css'; 

import Dave_Clark_2 from '../images/brand_logo/Dave_Clark_2.jpg';
import Incubeta from '../images/brand_logo/Incubeta.jpg';
import Multiplex_2 from '../images/brand_logo/Multiplex_2.jpg';
import RangeMe from '../images/brand_logo/RangeMe.jpg';
import Reebelo from '../images/brand_logo/Reebelo.jpg';
import Sendle from '../images/brand_logo/Sendle.jpg';

const logos = [
  "https://cdn.shopify.com/s/files/1/0612/1875/0717/files/PlaySide_7a0e8330-e99e-47ba-94ad-417e44cb686c.jpg?v=1684329192",
  "https://cdn.shopify.com/s/files/1/0612/1875/0717/files/Hardie_Grant.jpg?v=1684329192",
  "https://cdn.shopify.com/s/files/1/0612/1875/0717/files/Today.jpg?v=1684329192",
  "https://cdn.shopify.com/s/files/1/0612/1875/0717/files/Keypath_adf96fe2-75d7-480b-a872-b949bc0cf96f.jpg?v=1684329192",
  "https://cdn.shopify.com/s/files/1/0612/1875/0717/files/Comedy_Festival.jpg?v=1684329192",
  Dave_Clark_2,
  Incubeta,
  Multiplex_2,
  RangeMe,
  Reebelo,
  Sendle
];

const Brand = () => {
  return (

    
<>
<div>
<div className="logo-carousel-container">
      <div className="logo-carousel-track" >
         {/* Duplicate logos array for seamless scrolling */}
            {logos.concat(logos).map((logo, index) => (
              <div key={index} className="logo-item">
                <img src={logo} alt={`Logo ${index}`} />
              </div>
            ))}

    
  
        {/* {logos.map((logo, index) => (
          <div key={index} className="logo-item">
            <img src={logo} alt={`Logo ${index}`} />
          </div>
        ))}
        {logos.map((logo, index) => (
          <div key={index + logos.length} className="logo-item">
            <img src={logo} alt={`Logo ${index}`} />
          </div>
        ))}
        {logos.map((logo, index) => (
          <div key={index + logos.length} className="logo-item">
            <img src={logo} alt={`Logo ${index}`} />
          </div>
        ))}

        {logos.map((logo, index) => (
          <div key={index + logos.length} className="logo-item">
            <img src={logo} alt={`Logo ${index}`} />
          </div>
        ))} */}

        
      </div>
    </div>
    </div>

   
    </>
  );
};






export default Brand;
