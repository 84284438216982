import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../css/normalize.css';
import '../css/webflow.css';
import '../css/manmade-cycle-main.webflow.css';

const Signup = () => {
  const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    shop_name: '',
    password: '',
    cnf_password:'',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formStatus, setFormStatus] = useState(null);
  const [message, setMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleCnfPasswordVisibility = () => {
    setShowCnfPassword(!showCnfPassword);
  }; 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match before making the API call
    if (formData.password !== formData.cnf_password) {
      setPasswordError(true);
      setMessage('Passwords do not match.');
      return;
    } else {
      setPasswordError(false);
    }

    try {
      const response = await fetch('https://app.furloop.io/api/reseller/signup.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.message || "Registration successful. Redirecting...");
        setFormStatus('success');
      } else {
        setFormStatus('error');
        setMessage(data.message || 'Registration failed. Please try again.');
      }

      // Show the dialog
      const dialog = document.getElementById('successModal');
      if (dialog) {
        dialog.showModal();
      }

      if (response.ok) {
        setTimeout(() => {
          window.location.href = "https://app.furloop.io/reseller/";
        }, 5000);
      }

      setFormData(initialFormData);

    } catch (error) {
      console.error('Error:', error);
      setFormStatus('error');
      setMessage('An error occurred while processing your request.');

      // Show the dialog
      const dialog = document.getElementById('successModal');
      if (dialog) {
        dialog.showModal();
      }
    }
  };

  return (
    <div className="page-wrapper">
      <div className="section_half-half">
        <div className="wrap_50-p">
          <Link to="/" className="navbar-logo-link w-nav-brand">
            <div className="logo-brand-1 w-embed">
              <img
                src="https://cdn.shopify.com/s/files/1/0612/1875/0717/files/Furloop_copy.png?v=1725197268"
                alt="Furloop"
              />
            </div>
          </Link>
          <div className="content-wrap_center _100-p-width">
            <div className="form-wrapper left-align">
              <h1 className="h1_small">Create an account</h1>
              <div className="block_form w-form">
                <form onSubmit={handleSubmit} className="form-container">
                  <div className="flex-row_outer">
                    <div className="input-wrapper _15p-right">
                      <label htmlFor="firstName" className="input-label">
                        First Name
                      </label>
                      <input
                        className="input w-input"
                        maxLength="256"
                        name="firstName"
                        placeholder="e.g. Howard"
                        type="text"
                        id="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="lastName" className="input-label">
                        Last Name
                      </label>
                      <input
                        className="input w-input"
                        maxLength="256"
                        name="lastName"
                        placeholder="e.g. Thurman"
                        type="text"
                        id="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="email" className="input-label">
                      Email
                    </label>
                    <input
                      className="input w-input"
                      maxLength="256"
                      name="email"
                      placeholder="e.g. howard.thurman@gmail.com"
                      type="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="flex-row_outer">
                    <div className="input-wrapper _15p-right">
                      <label htmlFor="phoneNumber" className="input-label">
                        Phone Number
                      </label>
                      <input
                        className="input w-input"
                        maxLength="256"
                        name="phoneNumber"
                        placeholder="e.g. +61423281015"
                        type="text"
                        id="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="shopName" className="input-label">
                        Company Name
                      </label>
                      <input
                        className="input w-input"
                        maxLength="256"
                        name="shop_name"
                        placeholder="Company Name"
                        type="text"
                        id="shop_name"
                        value={formData.shop_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  {/* Password field with eye icon */}
                  <div className="input-wrapper">
                    <label htmlFor="password" className="input-label">
                      Password
                    </label>
                      <input
                        className={`input w-input ${passwordError ? 'error' : ''}`}
                        maxLength="256"
                        name="password"
                        placeholder="Enter your password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        style={{ paddingRight: '40px' }}
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={togglePasswordVisibility}
                      >
                        <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} />
                      </span>
                  </div>

                  {/* Confirm password field with eye icon */}
                  <div className="input-wrapper">
                    <label htmlFor="cnf_password" className="input-label">
                      Confirm Password
                    </label>
                   
                      <input
                        className={`input w-input ${passwordError ? 'error' : ''}`}
                        maxLength="256"
                        name="cnf_password"
                        placeholder="Confirm your password"
                        type={showCnfPassword ? 'text' : 'password'}
                        id="cnf_password"
                        value={formData.cnf_password}
                        onChange={handleChange}
                        required
                        style={{ paddingRight: '40px' }}
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={toggleCnfPasswordVisibility}
                      >
                        <i className={`fas ${showCnfPassword ? 'fa-eye-slash' : 'fa-eye'}`} />
                      </span>
                    
                  </div>
                  {passwordError && <p className="error-message">Password and confirm password do not match</p>}
                  <div className="spacer_xs"></div>
                  <input
                    type="submit"
                    className="button in-form w-button"
                    value="Sign Up"
                  />
                </form>
              </div>
              <div className="flex-row_center">
                <div className="faint-text">
                  Have an account?{' '}
                  <a
                    href="https://app.furloop.io/reseller/"
                    target="_blank"
                    className="link"
                    rel="noopener noreferrer"
                  >
                    Sign In
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>Copyright © 2024 Furloop</div>
        </div>
      </div>
      {/* Dialog Modal */}
      <dialog id="successModal" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            {formStatus === 'success' ? '🎉 Registration Successful' : 'Error'}
          </h3>
          <p className="py-4">{message}</p>
          {formStatus === 'success' && (
            <a
              href="https://app.furloop.io/reseller/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary mt-3"
            >
              Go to Dashboard
            </a>
          )}
          <div className="modal-action">
            <button className="btn" onClick={() => document.getElementById('successModal').close()}>
              Close
            </button>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default Signup;
