import React from 'react';
import '../css/MainSection.css'; // Import the CSS file for custom styles
import '../css/webflow.css';
import '../css/normalize.css';
import '../css/manmade-cycle-main.webflow.css'; 
import image1 from '../images/Group-1000002477.svg';
import image2 from '../images/Group-1000002473.svg';
import image3 from '../images/Group-1000002476.svg';
import image4 from '../images/Group-1000002474.svg';
import image5 from '../images/Group-1000002466-1.svg';
import image6 from '../images/Group-1000002478.svg';
import image7 from '../images/Group-1000002469.svg';
import backgroundImage from '../images/background_img_video.svg';

const MainSection = () => {
 
  return (
<>
<div className="main-section-header">
  <div className="main-container">

  <div className="row">
        <div className="col-md-4" style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
          <h4 className="main-section-video-text" style={{ textAlign: 'start', fontSize: '25px', fontWeight: '700', color: '#01017C', lineHeight: '1.5'}} >Create your own custom solution</h4>
          <p className="main-section-video-text mb-mobile-video-text" style={{ textAlign: 'start', fontSize: '14px', fontWeight: '500' }}>Take control of your trade-in program with
            complete flexibility. <br/>
            <br/>
            Easily create your own categories, products, and pricing rules. Start accepting trade-ins on your terms, anytime and anywhere.</p>
          </div>

          <div className="col-md-8 background-video-container" >
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: 1,
    }}
  ></div>

  <div
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 2,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <video
      width="94%"
      controls
      autoPlay
      muted
      style={{ display: 'block' }}
    >
      <source
        src="https://app.furloop.io/assets/video/furloop_video.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  </div>
</div>




      </div>

    <div className="content-wrap_center">
      <div
        id="w-node-a0f4c818-4abb-ddfd-6f6c-1c2a34baaa78-6232a524"
        data-w-id="a0f4c818-4abb-ddfd-6f6c-1c2a34baaa78"
        className="content-wrap_center mw-800 pt-5"
      >
        <h1 className="trade-in-header-testt">Trade-In Made Simple, Worldwide.</h1>
        <div className="spacer_xs"></div>
        <p className="paragraph-intro"></p>
      </div>
      <div className="text-block-3">
        FurLoop powers businesses across the globe to streamline their online and retail trade-in lead generation and operations, enabling them to focus on driving growth and success.
      </div>
      
    </div>
    <div className="w-layout-blockcontainer container-trade-in w-container">
      <section className="div-desk-upper">
        <address className="w-layout-hflex flex-block-upper">
          <img
            src={image1}
            loading="lazy"
            width="670"
            alt=""
            className="image-3"
          />
          <img
            src={image2}
            loading="lazy"
            width="500"
            alt=""
            className="image-2"
          />
        </address>
      </section>
      <div className="div-upper-mobile">
        <div className="w-layout-hflex flex-block-upper-mobile">
          <img
            src={image6}
            loading="lazy"
            width="Auto"
            alt=""
            className="image-3"
          />
          <img
            src={image7}
            loading="lazy"
            width="Auto"
            alt=""
            className="image-3"
          />
        </div>
      </div>
      <div className="div-desk-lower">
        <div className="w-layout-hflex flex-block-lower">
          <img
            src={image3}
            loading="lazy"
            width="460"
            alt=""
            className="image-3"
          />
          <img
            src={image4}
            loading="lazy"
            width="600"
            alt=""
            className="image-2"
          />
        </div>
      </div>
      <div className="div-desk-lower-mobile">
        <div className="w-layout-hflex flex-block-lower-mobile">
          <img
            src={image3}
            loading="lazy"
            width="Auto"
            alt=""
            className="image-3"
          />
          <img
            src={image5}
            loading="lazy"
            width="Auto"
            alt=""
            className="image-2"
          />
        </div>
      </div>
    </div>
  </div>
</div>


    </>
  );
};

export default MainSection;
