import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DefaultLayout from './layouts/DefaultLayout';
import SimpleLayout from './layouts/SimpleLayout';
import Home from './components/Home';
import Signup from './components/Signup';
import AboutUs from './components/AboutUs';




function App() {
  
  return (
    <div className="App">
    <Router>
      <Routes>
        {/* Routes using DefaultLayout */}
        <Route
          path="/"
          element={
            <DefaultLayout>
              <Home />
            </DefaultLayout>
          }
        />
         <Route
          path="/about-us"
          element={
            <DefaultLayout>
              <AboutUs />
            </DefaultLayout>
          }
        />
       {/* <Route
          path="/iso-app"
          element={
            <DefaultLayout>
              <ISOApp />
            </DefaultLayout>
          }
        />
        <Route
          path="/pricing"
          element={
            <DefaultLayout>
              <Pricing />
            </DefaultLayout>
          }
        /> */}

        {/* Routes using SimpleLayout */}
        <Route
          path="/sign-up-today"
          element={
                <SimpleLayout>
                  <Signup />
                </SimpleLayout>
           }
        />

       
      </Routes> 
    </Router>
 </div>
  );
}



export default App;
