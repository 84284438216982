import React, { useState } from 'react';
import '../index.css';
import '../css/Header.css';
import { Link } from 'react-router-dom';


const Header = () => {
  return (
    <header className="wrap_sticky">
      <a href="#" className="top-disclaimer w-inline-block">
        <div className="text-block-2">We have just launched! Try it out for free now!</div>
      </a>

      <div className="navbar bg-base-100 md:px-36">
        <div className="flex-1" style={{ display: 'inline-block' }}>
        <Link to="/" className="btn btn-ghost">
            <img
              src="https://cdn.shopify.com/s/files/1/0612/1875/0717/files/Layer_1.svg?v=1725726186" // Replace with the actual file path to your logo
              alt="FurLoop"
              className="logo-brand-1 w-embed" // Set the height of the logo
            />
          </Link>
        </div>
        <div className="flex-none">
          <ul className="menu p-[0rem] menu-horizontal">
          
            <li>
            <Link to="/sign-up-today" className="btn btn-outline btn-primary">Sign Up</Link>
            </li>
            <li>
              <a href="https://app.furloop.io/reseller/" className='btn btn-outlline btn-primary ml-2'>Log In</a>
            {/* <Link to="/sign-in-today" className="btn btn-outline btn-primary">Log In</Link> */}
            </li>
            <li className="flex justify-center ml-2">
              <details>
                <summary>More</summary>
                <ul className="bg-base-100 rounded-t-none p-2">
                  <li><a href='#pricing' className="no-underline">Pricing</a></li>
                  <li><Link to="about-us" style={{ textDecoration: 'none' }}>About US</Link></li>
                </ul>
              </details>
            </li>
          </ul>
        </div>
      </div>

      
    </header>
  );
};

export default Header;